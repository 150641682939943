<template>
  <SubPageWrapper data-shortcut-table>
    <CreateRuleModal :id="modalIds.REMOVAL_SUBSTITUTION_RULES_CREATE_RULE" />

    <template #loader>
      <SlOverlay :show="isProcessing" />
    </template>
    <template #left-col>
      <SlTabList
        v-model="tabModel"
        :tabs="tabs"
        collapsable
      />
    </template>
    <template #breadcrumbs>
      <SlBreadcrumb active>
        {{ $t('Substitutions.Ui.:Caption') }}
      </SlBreadcrumb>
    </template>
    <template #actions>
      <SlButton
        variant="secondary"
        color="grey"
        :disabled="!isEdited"
        @click="handleDiscard"
      >
        {{ $t('Web.Collections.Discard') }}
      </SlButton>
      <SlButton
        :disabled="!isEdited"
        @click="handleSave"
      >
        {{ $t('Web.Collections.SaveChanges') }}
      </SlButton>
    </template>
    <ContentBlock>
      <div class="subpage__actions">
        <div class="subpage__actions-group">
          <SlSearchInput
            :value="search"
            class="w-280"
            @submit="handleSearch"
          />
        </div>
        <SlSelectionInfo
          v-if="isActionsVisible"
          @shortcut:delete="handleDelete"
        >
          <template
            v-if="selectedRowsCount"
            #description
          >
            {{ $t('Web.Selection', { 1: selectedRowsCount }) }}
          </template>
          <template #actions>
            <SlButton
              variant="text"
              color="grey"
              @click="resetSelected"
            >
              <template #prepend>
                <icon
                  class="fill-off size-20 color-grey-80"
                  data="@icons/close.svg"
                />
              </template>
              {{ $t('Web.Deselect') }}
            </SlButton>
            <SlButton
              color="accent-red"
              @click="handleDelete"
            >
              <template #prepend>
                <icon
                  data="@icons/trash.svg"
                  class="fill-off size-16 color-white-stroke"
                />
              </template>
              {{ $t('EditCollections.Ui.butRemoveContainer') }}
            </SlButton>
          </template>
        </SlSelectionInfo>
        <div
          v-else
          class="subpage__actions-group"
        >
          <SlButton
            variant="secondary"
            color="grey"
            @click="showCreateModal"
          >
            <template #prepend>
              <icon
                data="@icons/plus.svg"
                class="fill-off size-16 color-grey-80"
              />
            </template>
            {{ $t('Web.Substitutions.BtnCreateRule') }}
          </SlButton>
          <SlDropdown
            :target-label="$t('Web.InTransition.BtnExportImport')"
            placement="bottom-end"
          >
            <template #content>
              <SlDropdownOption
                select-close
                @select="handleExportToXlsx"
              >
                {{ $t('Main.Ui.acChooseExportToXlsx') }}
              </SlDropdownOption>
              <SlDropdownOption
                select-close
                @select="handleImportFromXlsx"
              >
                {{ $t('Web.InTransition.ImportFromXLSX') }}
              </SlDropdownOption>
            </template>
          </SlDropdown>
        </div>
      </div>
      <div class="subpage__table-wrapper">
        <SlTabContent
          v-for="tab in tabs"
          :key="tab.value"
          :value="tab.value"
          :tab-value="tabModel"
        >
          <div class="subpage__table">
            <SlTable
              :id="tab.value"
              :ref="tab.value"
              :headers="tableHeaders"
              :rows="tableRows"
              unique-key="id"
              header-unique-key="key"
              header-name-key="name"
              header-type-key="type"
              :hidden-columns-keys="['id', checkboxKey]"
              :max-height="1000"
              :value-parser="parseValue"
              selectable
              pagination
              :total-rows-count="totalRowsCount"
              :current-page="currentPage"
              :per-page="perPage"
              :loading="loading"
              :inner-no-data="!!search"
              highlight-row
              @page-change="handlePaginationChange"
              @per-page-change="handlePerPageChange"
              @contextmenu:clipboard="handleContextMenu"
            >
              <template #loader>
                <SlOverlay :show="loading" />
              </template>

              <template #header-checkbox>
                <SlCheckbox
                  :value="headerCheckboxValue"
                  :indeterminate="isIndeterminate"
                  @change="handleSelectRowsAll"
                />
              </template>

              <template #row-checkbox="{ rowId, row }">
                <SlCheckbox
                  :value="isRowSelected(row)"
                  :disabled="isRowCheckboxDisabled(row)"
                  @change="(val) => handleSelectRows(val, rowId)"
                />
              </template>

              <template
                v-for="cellKey in currentTabKeys"
                #[cellKey]="slotScope"
              >
                <SlTableInput
                  :key="cellKey"
                  :value="slotScope.text"
                  :disabled="isCellDisabled(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="100"
                  type="text"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template
                v-if="isItemRules"
                #multiplier="slotScope"
              >
                <SlTableInput
                  :value="slotScope.text"
                  :disabled="isCellDisabled(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  :input-regexp="regExp.floatInput"
                  :submit-regexp="regExp.floatSubmit"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template
                v-if="isItemRules"
                #type="slotScope"
              >
                <div class="sl-table__cell-text">
                  {{ getTypeValue(slotScope.text) }}
                </div>
              </template>
              <template #target="slotScope">
                <SlTableInput
                  :value="getTargetValue(slotScope.text)"
                  :disabled="isCellDisabled(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  max-length="100"
                  type="text"
                  @submit="(val) => handleCellSubmit({ slotScope, val })"
                />
              </template>
              <template #date="slotScope">
                <SlTableDatepicker
                  :value="slotScope.text"
                  :disabled="isCellDisabled(slotScope)"
                  :foreground="getInputForeground(slotScope)"
                  :vid="`date-${slotScope.rowIndex}`"
                  @input="(val) => handleCellInput({ slotScope, val })"
                  @submit="() => handleCellSubmit({
                    slotScope,
                    val: slotScope.cellValue.val
                  })"
                />
              </template>
              <template #inUse="slotScope">
                <div class="sl-table__cell-text">
                  {{ getIsUsedValue(slotScope.text) }}
                </div>
              </template>

              <template #sourceDescription="slotScope">
                <div class="sl-table__cell-text">
                  {{ slotScope.text }}
                </div>
              </template>

              <template #no-data>
                <SlNoData>
                  <template #image>
                    <icon
                      :data="noDataMeta.icon"
                      :class="{
                        'illustration-md': !search,
                        'size-48': search,
                        'fill-off': search
                      }"
                    />
                  </template>
                  <template #title>
                    {{ noDataMeta.title }}
                  </template>
                  <template #text>
                    {{ noDataMeta.text }}
                  </template>
                </SlNoData>
              </template>
            </SlTable>
          </div>
        </SlTabContent>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CreateRuleModal from '@/components/Modals/SubstitutionRules/CreateRuleModal';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/webAPI';
import { tableConfig } from '@/mixins/tableConfig';
import { shortcut } from '@/mixins/shortcut';
import { exportTypes } from '@/config/report/inventoryReport';
import modalIds from '@/config/shared/modalsId.config';
import modalsId from '@/config/shared/modalsId.config';
import { csvAccepted } from '@/config/utils/fileUpload.config';
import {
  removalSubstitutionRulesKeys,
  removalSubstitutionRulesConfig,
  substitutionLabelCodeByType,
  substitutionRuleTypes
} from '@/config/item/substitutions.config';
import { checkboxStates } from '@/config/shared/slTable.config';
import { preventTabClose } from '@/helpers/shared/webAPI';
import { toArray } from '@/helpers/utils/toArray';
import regExp from '@/helpers/utils/regExp';
import { substitutionsTableClipboardMenuConfig } from '@/config/shared/clipboardContextMenu.config';

export default {
  name: 'RemovalSubstitutionRules',
  components: {
    CreateRuleModal
  },
  mixins: [modal, fileSaver, tableConfig, shortcut],
  data() {
    return {
      exportTypes,
      modalIds,
      regExp,
      csvAccepted,
      isRedirect: false,
      loading: false,
      isProcessing: false,
      checkboxKey: 'enabled'
    };
  },
  computed: {
    ...mapState({
      tab: state => state.substitutions.tab,
      [removalSubstitutionRulesKeys.ITEMS]: state => state.substitutions[removalSubstitutionRulesKeys.ITEMS],
      [removalSubstitutionRulesKeys.CHANNELS]: state => state.substitutions[removalSubstitutionRulesKeys.CHANNELS]
    }),
    ...mapGetters({
      getCreateData: 'substitutions/getCreateData',
      isEdited: 'substitutions/isEdited'
    }),
    tabModel: {
      get() {
        return this.tab;
      },
      set(value) {
        this.setTab(value);
      }
    },
    tableConfig() {
      return this[this.tabModel].tableConfig;
    },
    title() {
      return this.$t(substitutionLabelCodeByType[this.tabModel]);
    },
    tabs() {
      return removalSubstitutionRulesConfig(this);
    },
    table() {
      return this[this.tabModel].table || {};
    },
    tableHeaders() {
      return toArray(this.table.headers);
    },
    tableRows() {
      return toArray(this.table.rows);
    },
    totalRowsCount() {
      return this.table.totalRowsCount;
    },
    currentPage() {
      return this.table.currentPage;
    },
    currentTabKeys() {
      return this.tableHeaders.map(header => header.key);
    },
    isItemRules() {
      return this.tabModel === removalSubstitutionRulesKeys.ITEMS;
    },
    checkboxHeader() {
      return this.table.metadata?.checkbox;
    },
    selectedRowsCount() {
      return this.table.metadata?.selectedRowsCount;
    },
    isIndeterminate() {
      return this.checkboxHeader === checkboxStates.MIXED;
    },
    headerCheckboxValue() {
      return this.checkboxHeader !== checkboxStates.UNCHECKED;
    },
    isActionsVisible() {
      return !this.loading
        && (this.headerCheckboxValue || this.selectedRowsCount > 0)
        && this.tableRows.length;
    },
    noDataMeta() {
      if (this.search) {
        return {
          icon: require('@icons/style_no_search_results_double.svg'),
          title: this.$t('Web.NoData.SearchTitle'),
          text: this.$t('Web.NoData.SearchText')
        };
      }

      return {
        icon: require('@icons/illustration/no-table-rows.svg'),
        title: this.$t('Web.NoData.DefaultTitle'),
        text: this.$t('Web.NoData.PressToAddText', {
          1: this.$t('Web.Substitutions.BtnCreateRule')
        })
      };
    }
  },
  watch: {
    tabModel() {
      if (!this.tableHeaders.length) {
        this.fetchTables();
      }
    },
    isEdited(val) {
      preventTabClose(val);
    }
  },
  beforeMount() {
    this.fetchTables();
  },
  beforeDestroy() {
    preventTabClose();
  },
  destroyed() {
    this.resetState();
  },
  beforeRouteLeave(_, __, next) {
    if (!this.isRedirect) {
      return this.onRouteLeave(next);
    }

    next();
  },
  methods: {
    ...mapActions({
      fetchRules: 'substitutions/fetchRules',
      setTab: 'substitutions/setTab',
      updateTableConfig: 'substitutions/updateTableConfig',
      selectRow: 'substitutions/selectRow',
      updateCell: 'substitutions/updateCell',
      updateOverrides: 'substitutions/updateOverrides',
      createRule: 'substitutions/createRule',
      deleteRules: 'substitutions/deleteRules',
      saveOverrides: 'substitutions/saveOverrides',
      discardOverrides: 'substitutions/discardOverrides',
      updateRuleTables: 'substitutions/updateRuleTables',
      resetState: 'substitutions/resetState',
      importFromXlsx: 'substitutions/importFromXlsx',
      exportToXlsx: 'substitutions/exportToXlsx'
    }),
    async fetchTables() {
      try {
        this.loading = true;

        await this.fetchRules();
      } finally {
        this.loading = false;
      }
    },
    // route leave
    onRouteLeave(next) {
      if (!this.isEdited) {
        return next();
      }

      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.UnsavedConfirm.TitleUnsavedChanges'),
        text: this.$t('Web.Modals.UnsavedConfirm.TextUnsavedChanges'),
        icon: 'style_save_double',
        discardText: this.$t('Web.Modals.UnsavedConfirm.ButtonExitDiscard'),
        confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonSave'),
        discardCallback: async() => {
          await this.discardOverrides();
          this.isRedirect = true;

          next();
        },
        confirmCallback: async() => {
          await this.handleSave();

          this.isRedirect = true;

          next();
        }
      });
    },
    // search
    handleSearch(query) {
      if (!this.search && !this.totalRowsCount) {
        return;
      }

      this.search = query;

      this.fetchTables();
    },
    // create/delete
    async handleCreateRule(rule) {
      await this.createRule(rule);
      await this.handlePaginationChange(Math.ceil(this.totalRowsCount / this.perPage));

      this.$refs[this.tabModel][0]?.scrollToEnd();
    },
    showCreateModal() {
      this.showModal(modalsId.REMOVAL_SUBSTITUTION_RULES_CREATE_RULE, {
        inputData: this.getCreateData(this.tabModel),
        type: this.tabModel,
        createCallback: this.handleCreateRule
      });
    },
    // row selection
    isRowSelected(row) {
      return row[this.checkboxKey]?.val;
    },
    isRowCheckboxDisabled({ type }) {
      if (this.tabModel === removalSubstitutionRulesKeys.CHANNELS) {
        return;
      }

      return type?.val !== substitutionRuleTypes.CUSTOM;
    },
    resetSelected() {
      this.$refs[this.tabModel][0]?.resetSelected();

      this.updateOverrides({
        [this.checkboxKey]: false
      });
    },
    handleSelectRowsAll(checked) {
      this.updateOverrides({
        [this.checkboxKey]: checked
      });
    },
    handleSelectRows(checked, id) {
      this.selectRow({
        value: checked,
        rowId: id,
        cellKey: this.checkboxKey
      });

      this.updateOverrides({
        id,
        [this.checkboxKey]: checked
      });
    },
    // table configuration
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val || cellValue.auto || '';
    },
    isCellDisabled(slot) {
      /** DISABLED CELLS LOGIC:
       * Item rules (IR) can be imported (I) or custom (C) - (IRI\IRC)
       * IRI - can edit 'multiplier' and 'date' cols;
       * IRC - can edit everything except 'type' and 'inUse' cols;
       *
       * Channel rules (CR) are custom (C) by design - (CRC)
       * CRC - can edit everything except 'inUse' col (source, target, date);
       */
      if (
        slot.row?.type?.val === substitutionRuleTypes.CUSTOM
        || this.tabModel === removalSubstitutionRulesKeys.CHANNELS
      ) {
        return ['type', 'inUse'].includes(slot.cellKey);
      }

      return !['multiplier', 'date'].includes(slot.cellKey);
    },
    getIsUsedValue(cellValue) {
      if (cellValue === 'unknown') {
        return this.$t('Substitutions.TriBool.U');
      }

      return cellValue;
    },
    getTypeValue(cellValue) {
      if (cellValue === substitutionRuleTypes.CUSTOM) {
        return this.$t('Main.CreationType.Custom');
      }

      if (cellValue === substitutionRuleTypes.IMPORTED) {
        return this.$t('Main.CreationType.Import');
      }

      return '';
    },
    getTargetValue(cellValue) {
      if (this.tabModel === removalSubstitutionRulesKeys.ITEMS) {
        return cellValue || this.$t('Substitutions.Banned');
      }

      return cellValue || this.$t('Main.NoChannel');
    },
    getInputForeground(scope) {
      const val = scope.cellValue?.val;

      if (val) {
        return 'over';
      }

      return '';
    },
    formatCellValue({ slotScope, val }) {
      if (val === null) {
        return val;
      }

      return slotScope.cellKey === 'multiplier' ? +val : val;
    },
    handleCellInput({ slotScope, val }) {
      this.updateCell({
        value: val,
        rowId: slotScope.rowId,
        cellKey: slotScope.cellKey,
        cellIndex: slotScope.cellIndex
      });
    },
    handleCellSubmit({ slotScope, val }) {
      this.updateOverrides({
        id: slotScope.rowId,
        [slotScope.cellKey]: this.formatCellValue({ slotScope, val })
      });
    },
    handlePaginationChange(page) {
      this.page = page;

      return this.fetchTables();
    },
    handlePerPageChange(pageSize) {
      this.perPage = pageSize;
      this.page = 1;

      this.fetchTables();
    },
    // actions
    handleDelete() {
      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.Delete.Title', {
          1: this.$t('Web.Pluralization.Item.val', {
            p1: this.$tc('Web.Pluralization.Item.p1', this.selectedRowsCount, { 1: this.selectedRowsCount })
          })
        }),
        text: this.$t('Web.Modals.Delete.DescriptionBulk', {
          1: this.$tc('Web.Pluralization.ItThem', this.selectedRowsCount)
        }),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonDelete'),
        confirmCallback: this.deleteRules
      });
    },
    async handleSave() {
      if (!this.isEdited) {
        return;
      }

      try {
        this.isProcessing = true;

        const response = await this.saveOverrides();

        if (!response) {
          return;
        }

        this.$notify({
          type: 'warn',
          title: this.$t('Common.Info'),
          text: this.$t('Error.WarnApplyRules'),
          duration: -1
        });

        await this.updateRuleTables();
      } catch (error) {
        this.showModal(modalsId.NOTIFY, {
          title: this.$t('Error.Error'),
          text: error?.message
        });
      } finally {
        this.isProcessing = false;
      }
    },
    handleDiscard() {
      if (!this.isEdited) {
        return;
      }

      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.UnsavedConfirm.TitleDiscardChanges'),
        text: this.$t('Web.Modals.UnsavedConfirm.TextDiscardChanges'),
        icon: 'style_save_double',
        confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonDiscard'),
        confirmCallback: this.discardOverrides
      });
    },
    // export/import
    handleExportToXlsx() {
      this.saveFile(this.exportToXlsx);
    },
    handleImportFromXlsx() {
      if (!this.isEdited) {
        return this.showImportFileModal();
      }

      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.UnsavedConfirm.TitleDiscardChanges'),
        text: this.$t('Web.Modals.UnsavedConfirm.TextDiscardChanges'),
        icon: 'style_save_double',
        confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonDiscard'),
        confirmCallback: async() => {
          await this.discardOverrides();
          this.showImportFileModal();
        }
      });
    },
    showImportFileModal() {
      this.showModal(modalIds.SL_UPLOAD_FILE, {
        title: this.$t('Web.InTransition.ImportFile'),
        uploadHandler: this.importFromXlsx
      });
    },
    handleContextMenu(callback) {
      callback({
        updateCallback: this.handleCellSubmit,
        ...substitutionsTableClipboardMenuConfig(this)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.subpage__table-wrapper {
  height: calc(100% - 52px);
}
</style>
