<template>
  <SlModal
    :id="id"
    :title="modalTitle"
    persistent
    @created="onCreated"
    @on-enter="handleCreate"
    @hide="onModalClose"
    @show="onModalShow"
  >
    <ValidationObserver
      ref="observer"
      class="modal-content create-rule"
    >
      <div class="create-rule__row">
        <SlValidate
          v-slot="{ invalid }"
          rules="required"
          vid="source"
          class="w-50"
        >
          <SlCombobox
            :value="newItem.source"
            :select-label="inputData.source"
            :options="source"
            :loading="isLoading"
            :internal-search="false"
            :is-invalid="invalid"
            required
            @search="(val) => handleSearch(val, entityKeys.SOURCE)"
            @input="(val) => handleSelectChange(val, entityKeys.SOURCE)"
          />
        </SlValidate>
        <SlCombobox
          :value="newItem.target"
          :select-label="inputData.target"
          :options="target"
          :loading="isLoading"
          :internal-search="false"
          class="w-50"
          @search="(val) => handleSearch(val, entityKeys.TARGET)"
          @input="(val) => handleSelectChange(val, entityKeys.TARGET)"
        />
      </div>
      <div
        v-if="isItems"
        class="create-rule__row w-100"
      >
        <SlInput
          v-model="newItem.targetDescription"
          :label="inputData.targetDescription"
        />
      </div>
      <div 
        :class="{ 'w-50': !isItems }"
        class="create-rule__row"
      >
        <SlInput
          v-if="isItems"
          v-model="newItem.multiplier"
          :label="inputData.multiplier"
          type="number"
          float
        />
        <SlValidate
          v-slot="{ invalid }"
          rules="sl_valid_date|sl_date_between"
          vid="date"
          class="create-rule__datepicker"
        >
          <SlDatePicker
            v-model="newItem.date"
            :label="inputData.date"
            :is-invalid="invalid"
          />
        </SlValidate>
      </div>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleClose"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleCreate">
          {{ $t('Web.InTransition.BtnCreate') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import { EMPTY_CACHE_OVERRIDE } from '@/config/shared/magicValues.config';
import { removalSubstitutionRulesKeys } from '@/config/item/substitutions.config';
import { customDebounce } from '@/helpers/shared/listeners';

export default {
  name: 'CreateRuleModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      inputData: [],
      type: '',
      createCallback: null,
      newItem: {},
      source: [],
      target: [],
      isLoading: false,
      entityKeys: {
        SOURCE: 'source',
        TARGET: 'target'
      },
      searchActionByEntity: {
        [removalSubstitutionRulesKeys.ITEMS]: this.fetchItemCodes,
        [removalSubstitutionRulesKeys.CHANNELS]: this.fetchChannels
      }
    };
  },
  computed: {
    modalTitle() {
      if (this.isItems) {
        return this.$t('Web.Substitutions.TitleCreateItemRule');
      }

      return this.$t('Web.Substitutions.TitleCreateChannelRule');
    },
    isItems() {
      return this.type === removalSubstitutionRulesKeys.ITEMS;
    }
  },
  methods: {
    ...mapActions({
      fetchItemCodes: 'project/fetchItemCodes',
      fetchChannels: 'project/fetchChannels'
    }),
    onModalShow() {
      Object.keys(this.inputData).forEach(key => {
        this.$set(this.newItem, key,  '');
      });
    },
    onModalClose() {
      this.inputData = [];
      this.type = '';
      this.createCallback = null;
      this.newItem = {};
      this.source = [];
      this.target = [];
      this.isLoading = false;
    },
    searchEntities: customDebounce(async function(query, field) {
      try {
        this[field] = await this.searchActionByEntity[this.type]({ query });
      } finally {
        this.isLoading = false;
      }
    }, 200),
    async handleSearch(query, field) {
      if (!query) {
        return;
      }

      this.isLoading = true;

      this.searchEntities(query, field);
    },
    handleSelectChange(val, field) {
      const newValue = val === EMPTY_CACHE_OVERRIDE ? '' : val;

      this.$set(this.newItem, field, newValue);
    },
    async handleCreate() {
      if (!this.createCallback) {
        return;
      }

      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        return;
      }

      this.createCallback(this.newItem);
      this.hideModal(this.id);
    },
    handleClose() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/substitution-rules/create-rule-modal.scss";
</style>