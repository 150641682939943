import sortingApi from '@/api/sorting';
import { getTableSortingParams } from '@/helpers/shared/tableConfig';
import { namespaceByRoute } from '@/config/report';
import { customDebounce } from '@/helpers/shared/listeners';

// required:
// tableConfig store object
// updateTableConfig store action

export const tableConfig = {
  data() {
    return {
      oldSortOrder: null,
      oldCollClass: null,
      isDestroyed: false
    };
  },
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    search: {
      get() {
        return this.tableConfig.search;
      },
      set(value) {
        this.page = 1;

        this._updateConfig({
          key: 'search',
          value
        });
      }
    },
    perPage: {
      get() {
        return this.tableConfig.perPage;
      },
      set(value) {
        this._updateConfig({
          key: 'perPage',
          value
        });
      }
    },
    page: {
      get() {
        return this.tableConfig.page;
      },
      set(value) {
        this._updateConfig({
          key: 'page',
          value
        });
      }
    },
    sortOrder: {
      get() {
        return this.tableConfig.sortOrder;
      },
      set(value) {
        this._updateConfig({
          key: 'sortOrder',
          value
        });
      }
    },
    colClass: {
      get() {
        return this.tableConfig.colClass;
      },
      set(value) {
        this._updateConfig({
          key: 'colClass',
          value
        });
      }
    }
  },
  beforeMount() {
    this._updateQuery();
  },
  beforeRouteLeave(to, from, next) {
    this.isDestroyed = true;

    if (this.search) {
      this.search = '';
    }

    next();
  },

  methods: {
    // used debounce to prevent multiple router updates
    _updateQuery: customDebounce(function() {
      // prevent router updates if component is destroyed
      if (this.isDestroyed) {
        return;
      }

      this.$router.push({
        ...this.$route,
        query: this.tableConfig?.queryKeys?.reduce((acc, key) => {
          acc[key] = this.tableConfig[key] || undefined;

          return acc;
        }, {})
      });
    }, 200),
    async _updateConfig(payload) {
      await this.updateTableConfig(payload);

      this._updateQuery();
    },
    async setSortingParams({ config, params }) {
      try {
        await sortingApi.setSortingParams({
          ...params,
          ...getTableSortingParams(config),
          filterId: this.$store.getters[`${this.reportNamespace}/activeFilterId`]
        });
      } catch (error) {
        const message = error?.message;

        message && this.$notify({
          type: 'error',
          text: message
        });
      }
    }
  }
};
